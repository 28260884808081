import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { Card, CircularProgress, Typography } from "@mui/material";
import { getScatterplotData } from "../../../apis/plans.apis";

const ScatterplotGraph = ({ billboardId }) => {
  const [scatterplotData, setScatterplotData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getScatterplotData(billboardId);
        const formattedData = data.map((item) => ({
          time: item.time,
          duration: Number(item.duration),
        }));
        setScatterplotData(formattedData);
      } catch (error) {
        console.error("Error fetching scatterplot data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [billboardId]);

  const trace = {
    x: scatterplotData.map((d) => d.time),
    y: scatterplotData.map((d) => d.duration),
    mode: "markers",
    type: "scatter",
    marker: {
      size: 12,
      color: scatterplotData.map((d) => d.duration), // Color intensity based on duration
      colorscale: "YlOrRd",
      colorbar: {
        title: "Duration (s)"
      },
    },
    name: "Visibility Duration"
  };

  return (
    <Card sx={{ p: 2, borderRadius: 2 }}>
      <Typography variant="h6" textAlign="center">
        Scatterplot: Visibility Duration Over Time
      </Typography>
      {loading ? (
        <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
      ) : (
        <Plot
          data={[trace]}
          layout={{
            height: 400,
            margin: { t: 30, l: 50, r: 30, b: 70 },
            xaxis: {
              title: "Time",
              type: "category",
              tickangle: -45,
            },
            yaxis: {
              title: "Duration (Seconds)"
            },
            
          }}
          style={{ width: "100%", height: "100%" }}
          useResizeHandler
          config={{ responsive: true, displaylogo: false, displayModeBar: true }}
        />
      )}
    </Card>
  );
};

export default ScatterplotGraph;
