import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Plot from "react-plotly.js";
import { getCampaignVisibilityData } from "../../apis/campaigns.apis";
import { Paper, Typography, Box } from "@mui/material";

const AssetVisibilityLineChart = ({ campaignId }) => {
  const [plotData, setPlotData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await getCampaignVisibilityData(campaignId);
        const formattedLabels = result.map(
          (item) =>
            `${item.location} (${item.height}x${item.width})`
        );
        const xaxis = result.map((item) => item.asset_id);
        const minData = result.map((item) => item.min_visibility_duration);
        const maxData = result.map((item) => item.max_visibility_duration);
        const avgData = result.map((item) => item.avg_visibility_duration);

        setLabels(formattedLabels);
        setPlotData([
          {
            x: xaxis,
            y: minData,
            type: "scatter",
            mode: "lines+markers",
            name: "Min Visibility",
            line: { color: "red", width: 2 },
          },
          {
            x: xaxis,
            y: maxData,
            type: "scatter",
            mode: "lines+markers",
            name: "Max Visibility",
            line: { color: "green", width: 2 },
          },
          {
            x: xaxis,
            y: avgData,
            type: "scatter",
            mode: "lines+markers",
            name: "Avg Visibility",
            line: { color: "blue", width: 2 },
          },
        ]);
        toast.success("Graph plotted successfully!");
      } catch (error) {
        toast.error("Failed to plot visibility graph!");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [campaignId]);

  return (
    <Paper
      elevation={3}
      sx={{ p: 3, borderRadius: 2, backgroundColor: "#f5f5f5" }}
    >
      <Typography variant="h6" textAlign="center" mb={2} fontWeight="bold">
        Asset-wise Visibility Duration
      </Typography>
      <Loader open={isLoading} />
      {!isLoading && (
        <Box sx={{ width: "100%", height: 600 }}>
          <Plot
            data={plotData}
            layout={{
              title: "",
              height: 500,
              margin: { t: 40, r: 30, l: 60, b: 150 },
              xaxis: {
                title: "Billboards",
                tickvals: plotData[0]?.x, // Use x-axis from the first trace (asset_ids)
                ticktext: labels,    
                tickangle: -45,
                tickfont: { size: 10 },
              },
              yaxis: {
                title: "Visibility Duration",
                titlefont: { size: 14 },
              },
              legend: {
                orientation: "h",
                x: 0,
                y: 1.1,
              },
            }}
            config={{ responsive: true }}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      )}
    </Paper>
  );
};

export default AssetVisibilityLineChart;
