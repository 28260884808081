import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import { getMonthlyData } from "../../../apis/plans.apis";

const MonthlyBoxPlot = ({ billboardId }) => {
  const [plotData, setPlotData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getMonthlyData(billboardId); // Assumes format: [{ month: "Jan", values: [..] }, ...]
        
        // Convert to Plotly box plot format
        const traces = data.map((monthData) => ({
          y: monthData.values,
          name: monthData.month,
          type: "box",
          boxpoints: "outliers",
          jitter: 0.4,
          pointpos: 0,
          marker: { color: "#f39c1c" },
          line: { color: "#f39c1c" }
        }));

        setPlotData(traces);
      } catch (error) {
        console.error("Error fetching monthly box plot data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [billboardId]);

  return (
    <Card
      sx={{
        p: 3,
        borderRadius: 3,
        boxShadow: 3,
        maxWidth: "100%",
        minWidth: "900px",
        margin: "auto",
        mt: 4,
      }}
    >
      <Typography variant="h5" textAlign="center" gutterBottom>
        Monthly Visibility Duration Distribution
      </Typography>
      {loading ? (
        <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
      ) : (
        <Box sx={{ width: "100%", height: { xs: 400, md: 500 } }}>
          <Plot
            data={plotData}
            layout={{
              boxmode: "group",
              autosize: true,
              margin: { t: 50, l: 60, r: 30, b: 60 },
              yaxis: { title: "Visibility Duration (minutes)", zeroline: false },
              xaxis: { title: "Month" },
            }}
            useResizeHandler
            style={{ width: "100%", height: "100%" }}
            config={{ responsive: true, displaylogo: false, displayModeBar: true }}
          />
        </Box>
      )}
    </Card>
  );
};

export default MonthlyBoxPlot;
