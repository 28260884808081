import React from "react";
import { Grid, Typography, Box, Paper, Divider } from "@mui/material";
import Plot from "react-plotly.js";

export default function HourlyVisibilityLineGraph({ data }) {
  return (
    <Box mt={3} sx={{ width: "100%" }}>
      {Object.entries(data).map(([billboardId, values]) => {
        const hours = Object.keys(values.hourly_data);
        const avgDurations = hours.map((hour) => values.hourly_data[hour].avg_duration);
        const avgSpeeds = hours.map((hour) => values.hourly_data[hour].avg_speed);

        return (
          <Grid container justifyContent="center" key={billboardId}>
            <Grid item xs={12} md={10}>
              <Paper
                elevation={4}
                sx={{
                  p: 3,
                  mb: 4,
                  borderRadius: 3,
                  backgroundColor: "#f9f9f9",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ mb: 2, color: "primary.main", fontWeight: 600 }}
                >
                  ID: {billboardId}, {values.location}, {values.height} X {values.width}
                </Typography>
                <Divider sx={{ mb: 2 }} />

                {/* Combined Graph for Visibility Duration and Speed */}
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 2,
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, color: "grey.700" }}
                  >
                    Hourly Average Visibility Duration & Speed
                  </Typography>
                  <Plot
                    data={[
                      {
                        x: hours,
                        y: avgDurations,
                        type: "scatter",
                        mode: "lines+markers",
                        name: "Avg Visibility Duration (s)",
                        line: { color: "#1565c0" },
                        yaxis: "y1",
                      },
                      {
                        x: hours,
                        y: avgSpeeds,
                        type: "scatter",
                        mode: "lines+markers",
                        name: "Avg Speed (m/s)",
                        line: { color: "#d84315" },
                        yaxis: "y2",
                      },
                    ]}
                    layout={{
                      autosize: true,
                      margin: { t: 20, l: 50, r: 50, b: 50 },
                      xaxis: { title: "Time (Hour)", tickangle: -45 },
                      yaxis: {
                        title: "Avg Visibility Duration (s)",
                        side: "left",
                        color: "#1565c0",
                      },
                      yaxis2: {
                        title: "Avg Speed (m/s)",
                        side: "right",
                        overlaying: "y",
                        color: "#d84315",
                      },
                      paper_bgcolor: "#fff",
                      plot_bgcolor: "#fff",
                    }}
                    useResizeHandler={true}
                    style={{ width: "100%", height: "400px" }}
                    config={{ displayModeBar: true, displaylogo: false, responsive: true }}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}
