import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { getDayOfWeekData } from "../../../apis/plans.apis";
import { Card, CircularProgress, Typography, Box } from "@mui/material";

const DayOfWeekBoxPlot = ({ billboardId }) => {
  const [plotData, setPlotData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDayOfWeekData(billboardId);
        const weekdayData = response?.data || {};

        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const traces = weekdays.map(day => ({
          y: weekdayData[day] || [],
          name: day,
          type: 'box',
          boxpoints: 'all',
          jitter: 0.3,
          pointpos: -1.8,
          marker: { color: '#f39c1c' },
        }));

        setPlotData(traces);
      } catch (error) {
        console.error("Error fetching day of week data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [billboardId]);

  return (
    <Card
      sx={{
        p: 3,
        borderRadius: 3,
        boxShadow: 3,
        maxWidth: "100%",
        minWidth: "900px",
        margin: "auto",
        mt: 4,
      }}
    >
      <Typography variant="h5" textAlign="center" gutterBottom>
        Visibility Duration Distribution by Day of the Week
      </Typography>
      {loading ? (
        <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
      ) : (
        <Box sx={{ width: "100%", }}>
          <Plot
            data={plotData}
            layout={{
              boxmode: 'group',
              autosize: true,
              margin: { t: 50, l: 60, r: 30, b: 60 },
              yaxis: { title: "Scaled Duration (minutes)", zeroline: false },
              xaxis: { title: "Day of the Week" },
            }}
            style={{ width: "100%", height: "100%" }}
            useResizeHandler
            config={{ responsive: true, displaylogo: false, displayModeBar: true }}
          />
        </Box>
      )}
    </Card>
  );
};

export default DayOfWeekBoxPlot;
