import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { useParams } from "react-router-dom";
import { getDailyAverageAPI } from "../../../apis/plans.apis";
import { Card, CircularProgress, Typography } from "@mui/material";

const Linechart = () => {
  const { id } = useParams(); // Get billboard ID from URL
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDailyAverageAPI(id);
        setData(response);
      } catch (error) {
        console.error("Error fetching daily average data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [id]);

  const dateArray = data.map(item => item.date);
  const avgArray = data.map(item => item.avg_duration);
  const rollingArray = data.map(item => item.rolling_avg);

  const traces = [
    {
      x: dateArray,
      y: avgArray,
      type: "scatter",
      mode: "lines+markers",
      name: "Daily Avg Duration",
      line: { color: "#8884d8" }
    },
    {
      x: dateArray,
      y: rollingArray,
      type: "scatter",
      mode: "lines",
      name: "7-Day Rolling Avg",
      line: { color: "#82ca9d", dash: "dash" }
    }
  ];

  return (
    <Card sx={{ p: 2, boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h6" textAlign="center">
        Daily Average Visibility Duration
      </Typography>
      {loading ? (
        <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
      ) : (
        <Plot
          data={traces}
          layout={{
            title: "",
            height: 500,
            margin: { t: 30, l: 50, r: 20, b: 50 },
            xaxis: { title: "Date", type: "category", tickangle: -20 },
            yaxis: { title: "Minutes" },
            legend: { orientation: "h", y: -0.3 },
          }}
          style={{ width: "100%", height: "100%" }}
          useResizeHandler
          config={{ responsive: true, displaylogo: false, displayModeBar: true }}
        />
      )}
    </Card>
  );
};

export default Linechart;
