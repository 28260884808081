import React from "react";
import { Grid, Typography, Box, Paper, Divider } from "@mui/material";
import Plot from "react-plotly.js";

export default function DayOfWeekBoxPlot({ data }) {
  return (
    <Box mt={3}>
      {Object.entries(data).map(([billboardId, billboardData]) => (
        <Paper
          key={billboardId}
          elevation={4}
          sx={{
            p: 3,
            mb: 4,
            borderRadius: 3,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 2, color: "primary.main", fontWeight: 600 }}
          >
            ID: {billboardId}, {billboardData.location},{" "}
            {billboardData.height} X {billboardData.width}
          </Typography>
          <Divider sx={{ mb: 2 }} />

          <Grid container justifyContent="center">
            <Grid item xs={12} md={10}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 2,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 1, color: "grey.700" }}
                >
                  Duration Box Plot by Day of Week
                </Typography>
                <Plot
                  data={Object.entries(billboardData.days).map(
                    ([day, durations]) => ({
                      y: durations,
                      type: "box",
                      name: day,
                      marker: { color: "#f39c1c" },
                    })
                  )}
                  layout={{
                    margin: { t: 30, l: 40, r: 20, b: 40 },
                    height: 400,
                    width: "100%",
                    paper_bgcolor: "#fff",
                    plot_bgcolor: "#fff",
                    boxmode: "group",
                  }}
                  useResizeHandler={true}
                  style={{ width: "100%", height: "100%" }}
                  config={{ displayModeBar: true, displaylogo: false }}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Box>
  );
}
